// 个人中心 // 播放历史
<template>
  <div id="app3" class="pc_body">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>

    <div class="history_body" v-show="$store.state.islogin">
      <Leftside></Leftside>
      <div v-if="$store.state.islogin">
        <!-- 左侧 -->
        <transition name="RightToleft">
          <!-- 右侧主题 -->
          <div class="his_r_box" v-show="enter">
            <div class="history_body_right">
              <div class="his_r_title"><span>安全设置</span></div>
              <!-- 主体 -->
              <div class="his_r_body">
                <div class="rech">
                  <!-- <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'phoneBind')">
                  <span>绑定手机</span>
                  <img src="../assets/img/right.png" alt="前往修改密码内容" />
                </div> -->
                  <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'mailBind')">
                    <span>绑定邮箱</span>
                    <img src="../assets/img/right.png" alt="前往修改密码内容" />
                  </div>
                  <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'secReg')">
                    <span>修改密码</span>
                    <img src="../assets/img/right.png" alt="前往修改密码内容" />
                  </div>
                  <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'wuyeChange')">
                    <span>修改午夜版密码</span>
                    <img src="../assets/img/right.png" alt="前往修改密码内容" />
                  </div>
                  <!-- <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'secmailReg')">
                  <span>通过邮箱修改密码</span>
                  <img src="../assets/img/right.png" alt="下一页" />
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- 修改密码弹窗 -->
        <transition name="show">
          <div class="respowwin respowwin-changePow" v-show="$store.state.showlogin.secReg">
            <div class="login-logo">
              <img src="../assets/img/logo1.png" alt="弹窗的logo" />
            </div>
            <span class="Pop_titile">修改密码</span>
            <div id="loginIpu" class="sec_code_body_mailphone">
              <!-- 账号密码输入框 -->
              <div class="lt-d">
                <input
                  class="lt-input"
                  v-model="respass.original_password"
                  placeholder="请输入原密码"
                />
                <input
                  class="lt-input"
                  v-model="respass.new_password"
                  placeholder="请输入新密码"
                  type="password"
                />
                <input
                  class="lt-input"
                  v-model="conpass"
                  placeholder="请确认新密码"
                  type="password"
                />
              </div>
            </div>
            <!-- 登录/注册按钮 -->
            <div class="lt-f sec_emailphone">
              <div class="ltf-l" @click="YS.gotoVip($event, 'close', 'secReg')">
                <span>取消</span>
              </div>
              <div
                class="ltf-r"
                :class="{ cliloading: loadCli, 'ltf-r_u': ub_index == 3 }"
                @click="respow"
              >
                <span v-show="!loadCli">确定</span>
                <div class="dots-6" v-show="loadCli"></div>
              </div>
            </div>
            <span class="pop_forget" @click="cliForgetNightPow">忘记原密码？</span>
            <img
              class="login-close"
              @click="YS.gotoVip($event, 'close', 'secReg')"
              src="../assets/img/close.png"
              alt="关闭修改密码弹窗"
            />
          </div>
        </transition>
        <!-- 邮箱修改密码弹窗 -->
        <transition name="show">
          <div class="respowwin_mail" v-show="$store.state.showlogin.secmailReg">
            <div class="login-logo">
              <img v-if="ub_index == 3" src="../assets/u/logo1.png" alt="弹窗的logo" />
              <img v-else-if="ub_index == 2" src="../assets/bo/logo1.png" alt="弹窗的logo" />
              <img v-else src="../assets/img/logo.png" alt="弹窗的logo" />
            </div>
            <div id="loginIpu" class="sec_code_body">
              <!-- 单标签头 -->
              <div class="for-tag">
                <div id="ltc" class="lt-cc">
                  <span id="ltcspan">修改密码</span>
                </div>
              </div>
              <!-- 账号密码输入框 -->
              <div class="lt-d">
                <input class="lt-input" v-model="resmail.email" placeholder="请输入邮箱" />
                <input
                  class="lt-input"
                  v-model="resmail.new_password"
                  placeholder="请输入新密码"
                  type="password"
                />
                <div id="codeIpu" class="sec_code_inpu">
                  <input
                    class="lt-input"
                    maxlength="6"
                    v-model="resmail.captcha"
                    placeholder="请输入邮箱验证码"
                  />
                  <a id="codea" :class="{ cliloading: varCli }" class="varcode" @click="getmailver">
                    {{ $store.state.code.count }}
                  </a>
                </div>
              </div>
            </div>
            <!-- 登录/注册按钮 -->
            <div class="lt-f">
              <div class="lt-acc">
                <div class="sec_re_div" :class="{ cliloading: loadCli }" @click="cliresmail">
                  <a class="acc_re">
                    <span v-show="!loadCli">立即修改</span>
                    <div class="dots-6" v-show="loadCli"></div>
                  </a>
                </div>
              </div>
            </div>
            <img
              class="login-close"
              @click="YS.gotoVip($event, 'close', 'secmailReg')"
              src="../assets/img/close.png"
              alt="关闭邮箱修改密码弹窗"
            />
          </div>
        </transition>
        <!-- 绑定邮箱弹窗 -->
        <transition name="show">
          <div class="respowwin_mail" v-show="$store.state.showlogin.mailBind">
            <div class="login-logo">
              <img src="../assets/img/logo1.png" alt="logo图标" />
            </div>
            <div id="loginIpu" class="sec_code_body_mailphone">
              <div class="login-tag">
                <!-- 用户名注册 -->
                <div id="ltl" class="lt-l login-tag-act">
                  <span id="ltlspan">绑定邮箱</span>
                </div>
                <!-- 展位线 -->
                <div class="lt-c"></div>
                <div id="ltr" class="lt-r" @click="noneOpen()">
                  <!-- @click="
                  YS.gotoVip($event, 'open', 'phoneBind');
                  YS.gotoVip($event, 'close', 'mailBind');
                " -->
                  <span id="ltrspan">绑定手机</span>
                </div>
              </div>
              <!-- 账号密码输入框 -->
              <div class="lt-d">
                <input class="lt-input" v-model="resmail.email" placeholder="请输入邮箱" />
                <div id="codeIpu" class="sec_code_inpu">
                  <input
                    class="lt-input"
                    maxlength="6"
                    v-model="resmail.captcha"
                    placeholder="请输入邮箱验证码"
                  />
                  <a id="codea" class="varcode" :class="{ cliloading: varCli }" @click="getmailver">
                    {{ $store.state.code.count }}
                  </a>
                </div>
              </div>
            </div>
            <div class="lt-f sec_emailphone">
              <div class="ltf-l" @click="YS.gotoVip($event, 'close', 'mailBind')">
                <span>取消</span>
              </div>
              <div
                class="ltf-r"
                @click="climailRes()"
                :class="{ cliloading: loadCli, 'ltf-r_u': ub_index == 3 }"
              >
                <span v-show="!loadCli">确定</span>
                <div class="dots-6" v-show="loadCli"></div>
              </div>
            </div>
            <div class="lt-protocol">
              <input type="checkbox" name="vehicle" v-model="protocol" />
              <span>我已阅读并同意</span>
              <a @click="getpageapi('service-terms', 'protocol')">《UB影视用户协议》</a>
            </div>

            <img
              class="login-close"
              @click="YS.gotoVip($event, 'close', 'mailBind')"
              src="../assets/img/close.png"
              alt="关闭绑定邮箱弹窗"
            />
          </div>
        </transition>
        <!-- 绑定手机弹窗 -->
        <transition name="show">
          <div class="respowwin_mail" v-show="$store.state.showlogin.phoneBind">
            <div class="login-logo">
              <img src="../assets/img/logo1.png" alt="logo图标" />
            </div>
            <div id="loginIpu" class="sec_code_body_mailphone">
              <div class="login-tag">
                <!-- 用户名注册 -->
                <div
                  id="ltl"
                  class="lt-l"
                  @click="
                    YS.gotoVip($event, 'open', 'mailBind');
                    YS.gotoVip($event, 'close', 'phoneBind');
                  "
                >
                  <span id="ltlspan">绑定邮箱</span>
                </div>
                <!-- 展位线 -->
                <div class="lt-c"></div>
                <!-- 邮箱注册 -->
                <div id="ltr" class="lt-r login-tag-act">
                  <span id="ltrspan">绑定手机</span>
                </div>
              </div>
              <!-- 账号密码输入框 -->
              <div class="lt-d">
                <div class="lt-input-phoneSgin-pc">
                  <VueCountryIntl
                    class="country_act"
                    onlyValue
                    searchAble
                    showAreaCode
                    useChinese
                    iosMobileReadonly
                    scheme="input"
                    @onChange="country"
                    :readonly="schemaInput.readonly"
                    v-model="schemaInput.default"
                    :iso2="schemaInput.selectedObjDefault.iso2"
                    schema="input"
                  ></VueCountryIntl>
                  <input
                    class="phoneSgin_input"
                    v-model="bindPhone.phone"
                    placeholder="请输入手机号"
                  />
                </div>
                <div id="codeIpu" class="sec_code_inpu">
                  <input
                    class="lt-input"
                    maxlength="6"
                    v-model="resmail.captcha"
                    placeholder="请输入手机验证码"
                  />
                  <a id="codea" class="varcode">{{ $store.state.code.count }}</a>
                </div>
              </div>
            </div>
            <div class="lt-f sec_emailphone">
              <div class="ltf-l" @click="YS.gotoVip($event, 'close', 'phoneBind')">
                <span>取消</span>
              </div>
              <div class="ltf-r" :class="{ cliloading: loadCli, 'ltf-r_u': ub_index == 3 }">
                <span v-show="!loadCli">确定</span>
                <div class="dots-6" v-show="loadCli"></div>
              </div>
            </div>
            <div class="lt-protocol">
              <input type="checkbox" name="vehicle" v-model="protocol" />
              <span>我已阅读并同意</span>
              <a @click="getpageapi('service-terms', 'protocol')">《UB影视用户协议》</a>
            </div>

            <img
              class="login-close"
              @click="YS.gotoVip($event, 'close', 'phoneBind')"
              src="../assets/img/close.png"
              alt="关闭绑定邮箱弹窗"
            />
          </div>
        </transition>
        <!-- 修改午夜版密码 -->
        <transition name="show">
          <div class="respowwin respowwin-changePow" v-show="$store.state.showlogin.wuyeChange">
            <div class="login-logo">
              <img src="../assets/img/logo1.png" alt="弹窗的logo" />
            </div>
            <span class="Pop_titile">修改午夜版密码</span>
            <div id="loginIpu" class="sec_code_body_mailphone">
              <!-- 账号密码输入框 -->
              <div class="lt-d">
                <input
                  class="lt-input"
                  v-model="respass.original_password"
                  placeholder="请输入原密码，默认密码1818"
                />
                <input
                  class="lt-input"
                  v-model="respass.new_password"
                  placeholder="请输入新密码"
                  type="password"
                />
                <input
                  class="lt-input"
                  v-model="conpass"
                  placeholder="请确认新密码"
                  type="password"
                />
              </div>
            </div>
            <!-- 登录/注册按钮 -->
            <div class="lt-f sec_emailphone">
              <div class="ltf-l" @click="YS.gotoVip($event, 'close', 'wuyeChange')">
                <span>取消</span>
              </div>
              <div
                class="ltf-r"
                :class="{ cliloading: loadCli, 'ltf-r_u': ub_index == 3 }"
                @click="resWuyepow"
              >
                <span v-show="!loadCli">确定</span>
                <div class="dots-6" v-show="loadCli"></div>
              </div>
            </div>

            <img
              class="login-close"
              @click="YS.gotoVip($event, 'close', 'wuyeChange')"
              src="../assets/img/close.png"
              alt="关闭修改密码弹窗"
            />
          </div>
        </transition>
        <!-- 遮罩层 -->
        <div id="cover"></div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
export default {
  components: {
    Top,
    Leftside,
  },
  name: 'History',
  data() {
    return {
      varCli: false,
      schemaInput: {
        default: '+1',
        readonly: false,
        //noCode: '',
        disableCountry: '',
        onlyCountry: '',
        selectedObjDefault: {},
        iso2: '',
      },
      loadCli: false,
      enter: false,
      conpass: '', //确认密码
      protocol: true, //协议
      //重置密码表单数据
      respass: {
        original_password: '',
        new_password: '',
      },
      // 通过邮箱充值密码表单数据
      resmail: {
        email: '',
        new_password: '',
        captcha: '',
        phrase_id: '',
      },
      bindPhone: {
        username: '',
        phone: '',
        password: '',
        captcha: '',
        phrase_id: '',
        res: '',
      },
      bindEmail: {
        username: '',
        phone: '',
        password: '',
        captcha: '',
        phrase_id: '',
        res: '',
      },
      secType: {
        def: { title: '修改密码', codeShow: false },
        mail: { title: '通过邮箱修改密码', codeShow: true },
      },
      ub_index: 1,
    };
  },
  created() {
    that = this;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (that.YS.ismob()) {
      //移动端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.MOBILE_URL_BO
          : window.g.ub_index == 3
          ? window.g.MOBILE_URL_U
          : window.g.MOBILE_URL + '/mcenter.html?go=safe';
    }
    this.ub_index = window.g.ub_index;
  },
  mounted() {
    that;
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 5, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 5); //添加默认选中样式
  },
  methods: {
    // 忘记午夜版密码
    cliForgetNightPow() {
      that.YS.gotoVip({}, 'open', 'res_mail');

      that.YS.gotoVip({}, 'close', 'secReg');
    },
    //暂未开放
    noneOpen() {
      that.$refs.top.messTop = '暂未开放手机绑定，敬请期待';
      that.$refs.top.mess_show = true;
      setTimeout(() => {
        that.$refs.top.mess_show = false;
      }, 3000);
    },
    //绑定邮箱
    climailRes() {
      if (that.loadCli) return;
      that.loadCli = true;
      that.api.user
        .api_secritybindEmail(that.resmail)
        .then((data) => {
          var aaa = JSON.parse(localStorage.getItem('user'));
          aaa.email = data.user.email;
          localStorage.setItem('user', JSON.stringify(aaa));
          that.$store.commit('changelogin');
          // alert('绑定邮箱成功');
          that.$refs.top.usepubilDialog({
            title: '绑定邮箱成功',
            type: 'vip-card',
            code: true,
          });
          that.YS.gotoVip(1, 'close', 'mailBind');
        })
        .catch((error) => {
          // alert(error.message);
          that.$refs.top.usepubilDialog({
            title: error.message,
            type: 'vip-card',
            code: false,
          });
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    country(e) {
      // debugger;
      // console.log(e.dialCode);
      // console.log(that.countryCode);

      if (e.dialCode == 86) {
        that.$refs.top.ubnot = {
          title: '本站暂不对中国大陆开放，敬请谅解',
          text: ' ',
          button: '重新选择',
        };
        that.YS.gotoVip({}, 'open', 'ubPop');
        that.loadCli = false;
        e.dialCode = '';
        return;
      }
    },
    // 重置密码
    respow() {
      if (that.loadCli) return false;
      that.loadCli = true;
      if (
        !!that.conpass &&
        !!that.respass.new_password &&
        that.conpass === that.respass.new_password
      ) {
        that.api.user
          .api_changePassword(that.respass)
          .then((data) => {
            that.YS.gotoVip({}, 'close', 'secReg');
            localStorage.setItem('token', JSON.stringify(data.token));
            that.$refs.top.ubsucc.title = '修改密码成功!';
            that.YS.gotoVip({}, 'open', 'ubPop_succ');
            setTimeout(() => {
              that.YS.gotoVip({}, 'close', 'ubPop_succ');
              that.YS.gotoVip({}, 'close', 'secReg');
            }, 3000);
            // that.$refs.top.usepubilDialog({ title: '修改密码成功', code: true });
          })
          .catch((error) => {
            that.$refs.top.usepubilDialog({ title: error.message, code: false });
          })
          .finally(() => {
            that.loadCli = false;
          });
      } else if (that.conpass != that.respass.new_password) {
        that.loadCli = false;
        that.$refs.top.ubnot = {
          title: '您输入的密码前后不一致',
          text: '请返回修改',
          button: '重新填写密码',
        };
        that.YS.gotoVip({}, 'open', 'ubPop');
        // that.$refs.top.usepubilDialog({ title: '两次输入密码不一致', code: false });
      }
    },
    // 重置午夜版密码
    resWuyepow() {
      if (that.loadCli) return false;
      that.loadCli = true;
      if (
        !!that.conpass &&
        !!that.respass.new_password &&
        that.conpass === that.respass.new_password
      ) {
        that.api.user
          .api_secritychangeNightPassword({
            original_password: that.respass.original_password,
            new_password: that.respass.new_password,
          })
          .then(() => {
            that.YS.gotoVip({}, 'close', 'wuyeChange');
            that.$refs.top.ubsucc.title = '修改午夜版密码成功!';
            that.YS.gotoVip({}, 'open', 'ubPop_succ');
            setTimeout(() => {
              that.YS.gotoVip({}, 'close', 'ubPop_succ');
              that.YS.gotoVip({}, 'close', 'secReg');
            }, 3000);
            // that.$refs.top.usepubilDialog({ title: '修改密码成功', code: true });
          })
          .catch((error) => {
            that.$refs.top.usepubilDialog({ title: error.message, code: false });
          })
          .finally(() => {
            that.loadCli = false;
          });
      } else if (that.conpass != that.respass.new_password) {
        that.loadCli = false;
        that.$refs.top.ubnot = {
          title: '您输入的密码前后不一致',
          text: '请返回修改',
          button: '重新填写密码',
        };
        that.YS.gotoVip({}, 'open', 'ubPop');
        // that.$refs.top.usepubilDialog({ title: '两次输入密码不一致', code: false });
      }
    },
    //通过邮箱重置密码
    cliresmail() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.api.user
        .api_resetPasswordByEmail(that.resmail)
        .then((data) => {
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          that.$refs.top.usepubilDialog({ title: '修改密码成功', code: true });

          that.YS.gotoVip(1, 'close', 'secmailReg');
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    //获取邮箱验证码
    getmailver() {
      if (that.varCli) return;
      that.varCli = true;
      if (that.$store.state.code.count === '验证码') {
        that.api.user
          .api_emailCode({ email: that.resmail.email })
          .then((data) => {
            that.YS.getCode();
            that.resmail.phrase_id = data.phrase_id;
          })
          .finally(() => {
            that.varCli = false;
          });
      } else {
        that.varCli = false;
      }
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
